<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="5%"
    width="80%"
    :center="true"
    :before-close="doClose"
  >
    <div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="关联试卷" name="first">
          <paperTable ref="paperTable" @eventBus="tableBack"/>
        </el-tab-pane>
        <el-tab-pane label="新增试卷" name="second">
          <paperForm
            ref="paperForm"
            @eventBusOk="formBack"
            @eventBusClose="doClose"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-dialog>
</template>
<script>
import paperTable from "@/views/resourse/popup/PaperTable";
import paperForm from "@/views/resourse/popup/PaperForm";
export default {
  name: "course",
  components: {
    paperForm,
    paperTable
  },
  data() {
    return {
      dialogVisible: false,
      activeName: "first",
      stu: false,
    };
  },
  computed: {},
  methods: {
    showPopUp() {
      this.dialogVisible = true;
      if(this.stu){
        this.$refs.paperTable.getData();
      }
      this.stu = true;
    },
    doClose() {
      this.dialogVisible = false;
    },
    // tab回传
    tableBack(id) {
      this.parentPsBack(id);
    },
    formBack(id) {
      this.parentPsBack(id);
    },
    // 给父组件传参
    parentPsBack(id) {
      this.$emit("eventBus", id);
      this.doClose();
    }
  }
};
</script>
<style lang="less"></style>
