<template>
  <div class="teacher-picker">
    <el-dialog title="关联讲师" :visible.sync="show" @close="close" style="z-index:100001">
      <el-form :rules="rules" :model="form" ref="form" label-width="10rem">
        <el-form-item label="讲师:" prop="chosenTeacher">
          <div class="df">
            <el-select 
              v-model="form.chosenTeacher" 
              @change="selectTeacher"
              clearable
              filterable
              size="small"
              placeholder="请选择讲师">
            <el-option
              v-for="item in teacherTree"
              :key="item.teacherQualificationId"
              :label="item.teacherQualificationName"
              :value="item.teacherQualificationId">
              <div style="display: flex;justify-content:space-between;align-items:center">
                <span>{{ item.teacherQualificationName }}</span>
                <el-popover
                  placement="right"
                  v-if="item.teacherQualificationId!= '-1000'"
                  trigger="hover">
                  <el-button type="text" slot="reference">证书</el-button>
                  <el-image
                    style="width: 500px; height: auto;"
                    fit="contain"
                    :src="item.qualificationImageUrl">
                  </el-image>
                </el-popover>
              </div>
            </el-option>
          </el-select>
            <el-button
             size="small" style="margin-left: 10px;" class="bgc-bv" @click="addTeacher"
            >新增讲师</el-button>
          </div>
        </el-form-item>
        <el-form-item v-if="batch" label="课件" prop="chosenFiles">
          <el-select multiple clearable v-model="form.chosenFiles">
            <template slot="prefix">
              <el-tooltip content="全选" placement="top-start">
                <i class="el-icon-finished check-all-btn" @click="checkAllFiles"></i>
              </el-tooltip>
            </template>
            <el-option
              v-for="item in fileList"
              :key="item.videoId"
              :label="item.dispName"
              :value="item.videoId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="close" class="bgc-bv">取 消</el-button>
        <el-button class="bgc-bv" @click="doSave">确 定</el-button>
      </div>
    </el-dialog>
    <Teacherpop ref="Teacherpop" @getList="getTeacherData" />
  </div>
</template>
<script>
import Teacherpop from "@/views/resourse/popup/Teacherpop";

export default {
  name: "teacher-picker-pop",
  components: {
    Teacherpop
  },
  props: {
    show: Boolean,
    fileList: [],
    batch: {
      default: false
    }
  },
  data() {
    return {
      rules: {
        chosenTeacher: [
          { required: true, message: "请选择讲师", trigger: "blur" }
        ],
        chosenFiles: [
          { required: true, message: "请选择课件", trigger: "blur" }
        ]
      },
      teacherTree: [],
      selTeacher: {},
      form: {
        chosenTeacher: [],
        chosenFiles: []
      }
    };
  },
  watch: {
    show(val) {
      if (val) {
        this.getTeacherData();
      }
    }
  },
  created() {
    this.getTeacherData();
  },
  methods: {
    selectTeacher(qualificationId) {
      if (qualificationId === '-1000') {
        let sel = {
          id: qualificationId,
          name: "无讲师",
          idcard: "",
          qualification: {
            id: qualificationId,
            name: "无资质"
          }
        };
        this.selTeacher = sel;
      }else{
        this.teacherTree.forEach(teacher => {
          if(teacher.teacherQualificationId === qualificationId){
            let sel = {
              id: teacher.teacherQualificationId,
              name: teacher.teacherName,
              idcard: teacher.teacherIdcard,
              qualification: {
                id: teacher.teacherQualificationId,
                name: teacher.qualificationTypeName
              }
            };
            this.selTeacher = sel;
          }
        });
      }
      console.log(this.selTeacher);
    },
    addTeacher() {
      this.$refs.Teacherpop.showPopUp();
    },
    getTeacherData() {
      this.$post("/biz/teacher/queryQualificationDictionary", {}).then(res => {
        if (res.status == 0) {
         this.teacherTree = [...res.data,{teacherQualificationId:'-1000',teacherQualificationName:'无讲师'}]
        }
      });
    },
    close() {
      this.form.chosenTeacher = [];
      this.selTeacher = {};
      this.form.chosenFiles = [];
      this.$refs.form.clearValidate();
      this.$emit("update:show", false);
    },
    doSave() {
      let re = this.$refs.form.validate(re => {
        if (re) {
          let files = [];
          if (!this.batch) {
            files = this.fileList;
          } else {
            files = this.fileList.filter(file =>
              this.form.chosenFiles.includes(file.videoId)
            );
          }
          this.$emit("picked", this.selTeacher, files);
          this.close();
        }
      });
    },
    checkAllFiles() {
      this.form.chosenFiles = [];
      this.fileList.forEach(file => this.form.chosenFiles.push(file.videoId));
    }
  }
};
</script>

<style lang="less" scoped>
.teacher-picker {
  .check-all-btn {
    font-size: 22px;
    line-height: inherit;
    color: #409eff;
    cursor: pointer;
  }
  .check-all-btn:hover {
    color: #66b1ff;
  }
}
</style>