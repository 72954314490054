<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="1%"
    width="90%"
    :center="true"
    title="关联内容"
    :before-close="doClose"
  >
    <div style="height:650px">
      <el-tabs v-model="activeName" @tab-click="tabsChange">
        <el-tab-pane
          label="关联课件"
          name="first"
          :disabled="state == 'unitTest'"
        >
          <CourseTable ref="CourseTable" @eventBus="tableBack" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-dialog>
</template>
<script>
import CourseTable from "./CourseTable";
export default {
  name: "course",
  components: {
    CourseTable,
  },
  data() {
    return {
      dialogVisible: false,
      activeName: "first",
      stu: false,
      state: "all",
      minishow:false,
    };
  },
  methods: {
    tabsChange(e) {
      this.activeName = e.name;
      if (this.activeName == "first") {
        this.$refs.CourseTable.getData();
      } else if (this.activeName == "second") {
        this.$refs.paperTable.getData();
      }
    },
    showPopUp(state,minishow) {
      this.dialogVisible = true;
      this.state = state;
      this.minishow = minishow;
      console.log(this.minishow);
       this.activeName = state=="unitTest"?"second":"first";
      if (this.stu) {
        if (state == "all") {
          this.$refs.CourseTable.getData();
        }
        if (state == "course") {
          this.$refs.CourseTable.getData();
        }
        if (state == "unitTest") {
          this.$refs.paperTable.getData();
        }
      }
      this.stu = true;
    },
    doClose() {
      this.dialogVisible = false;
    },
    // tab回传
    tableBack(id) {
      this.parentPsBack(id);
    },
        // tab回传
    unitBack(id) {
      this.parentPsBack(id);
    },
    formBack(id) {
      this.parentPsBack(id);
    },
    // 给父组件传参
    parentPsBack(ret) {
      this.$emit("eventBus", ret);
      this.doClose();
    }
  }
};
</script>
<style lang="less"></style>
