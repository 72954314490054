<template>
  <section>
    <div class="ovy-a">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="12rem"
        class="commodity-form"
      >
        <el-form-item label="试卷名称：" prop="paperName" class="form-item">
          <el-input
            v-model="ruleForm.paperName"
            :disabled="ruleForm.id ? true : false"
            placeholder="请输入试卷名称"
          />
        </el-form-item>
        <el-form-item label="培训类型：" prop="Trainingtype" class="form-item">
          <tree
            ref="tree"
            @eventBtn="childBack"
            :ruleForm="ruleForm"
            @clearParams="clearParams"
            :modal="false"
            type
            typeStu
          />
        </el-form-item>
        <el-form-item label="通过分数：" prop="paperScore" class="form-item">
          <el-input-number v-model="ruleForm.paperScore" :min="1" size="small"></el-input-number>
        </el-form-item>
        <el-form-item label="下载模板：" class="form-item">
          <el-button @click="downloadExcel">下载</el-button>
        </el-form-item>
        <el-form-item label="上传试题：" prop="fileName">
          <el-upload
            v-model="ruleForm.fileName"
            v-if="!ruleForm.fileName"
            class="upload-demo upload-btn"
            :action="actionUrl"
            :on-error="handleError"
            :on-success="handleSuccess"
            :on-change="uploadChange"
            :show-file-list="false"
            :auto-upload="false"
          >
            <el-button size="small" class="bgc-bv">选取试题</el-button>
          </el-upload>
          <span v-else style="color:#f46173">
            {{ ruleForm.fileName }}
            <a @click="reomveExl" style="color: #5bb5ff">删除</a>
          </span>
        </el-form-item>
        <el-form-item label=" " class="form-item form-item-hint">
          <p>只能上传.XLS 和 .XLSX格式的单个文件，且文件大小不超过1MB！</p>
        </el-form-item>
        <el-form-item label="是否发布在平台销售：" prop="salesState" class="form-item form">
          <div>
            <el-radio-group v-model="ruleForm.salesState" @change="radioChange">
              <el-radio :label="20">是</el-radio>
              <el-radio :label="10">否</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <!-- <el-form-item el-form-item label=" " class="form-item form" v-show="checkShow">
          <el-checkbox v-model="checked">请阅读本协议《平台课程服务协议》</el-checkbox>
        </el-form-item>-->
        <el-form-item class="form-btn">
          <el-button class="bgc-bv" @click="dialogClose">取消</el-button>
          <el-button class="bgc-bv" @click="formSave('ruleForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>
<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import Form from "@/mixins/Form";
import tree from "@/components/treePopup";
export default {
  name: "BusinessAdd",
  mixins: [UploadPic, DatePicker, Form],
  components: {
    tree
  },
 
  data() {
    return {
      actionUrl: "",
      ruleForm: {
        id: null,
        paperName: "",
        paperScore: 60,
        excelUrl: "",
        salesState: 10,
        Trainingtype:'',
        fileName: ""
      },
      props: {
        value: "id",
        label: "label"
      },
      params: {},
      //是否上传文件
      updateFile: false,
      rules: {
        paperName: [
          { required: true, message: "请输入试卷名称", trigger: "blur" }
        ],
        paperScore: [
          { required: true, message: "请输入通过分数", trigger: "blur" }
        ],
        salesState: [
          { required: true, message: "请选择销售状态", trigger: "blur" }
        ],
        Trainingtype: [
          { required: true, message: "请选择培训类型", trigger: "change" }
        ],
        fileName: [{ required: true, message: "请上传试卷", trigger: "blur" }]
      },
      checkShow: false
    };
  },
  computed: {},
  created() {},
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    init() {
    },
    // 保存
    formSave(formName = "ruleForm") {
      const el = this.$refs[formName];
      el.validate(valid => {
        if (valid) {
          // console.log(this.ruleForm);
          this.doAjax();
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项"
          });
        }
      });
    },
    radioChange(val) {
      if (val == 20) {
        this.checkShow = true;
      } else {
        this.checkShow = false;
      }
    },
    downloadExcel() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/paper.xls";
      link.setAttribute("download", "paper.xls");
      document.body.appendChild(link);
      link.click();
    },
    doAjax() {
      let params = {
        id: null,
        paperName: this.ruleForm.paperName,
        paperScore: this.ruleForm.paperScore,
        excelUrl: this.ruleForm.excelUrl,
        salesState: 10,
        fileName: this.ruleForm.fileName,
        paperOrgin: 10
      };
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      if (this.ruleForm.excelUrl == "") {
        this.$message.warning("请先上传试题");
        return false;
      }
      this.$post("/biz/paper/insertPaperQuestion", params)
        .then(re => {
          setTimeout(() => {
            this.$message({
              type: "success",
              message: "保存成功"
            });
          }, 300);
          this.$emit("eventBusOk", re.data);
          this.reset();
        })
        .catch(err => {
          return;
        });
    },
    /**
     *
     */
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      this.ruleForm.fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then(result => {
          this.ruleForm.excelUrl = result.data.fileKey;
          this.$refs["ruleForm"].clearValidate("fileName");
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败"
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    reomveExl() {
      this.ruleForm.fileName = "";
      this.ruleForm.excelUrl = "";
      this.actionUrl = "";
      this.$forceUpdate();
    },
    dialogClose() {
      this.$emit("eventBusClose");
      this.reset();
    },
    reset() {
      this.$emit("eventBusClose");
       this.ruleForm = {
        id: null,
        paperName: "",
        paperScore: 60,
        excelUrl: "",
        salesState: 10,
        Trainingtype:'',
        fileName: ""
      }
    }
  }
};
</script>
<style lang="less">
.formCom {
  .el-form-item {
    margin-bottom: 22px !important;
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
  }
  .upload-btn {
    height: 40px;
    .el-upload {
      height: 40px !important;
      border: none !important;
    }
  }
  .el-tree-node__content {
    height: 2rem !important;
  }
}
</style>
