<template>
	<div>
		<el-dialog title="编辑课件信息" :visible.sync="show" @close="close">
			<el-form :model="origin" :rules="rules" ref="form" label-width="10rem">
				<el-form-item label="课件名称:" prop="dispName">
					<el-input v-model="origin.dispName" maxlength="20" :show-word-limit="true" placeholder="请输入名称"/>
				</el-form-item>
				<el-form-item label="学时:" prop="kpointLessonNum">
					<el-input-number size="mini" v-model="origin.kpointLessonNum" :min="0.1" :max="100" :step="0.1" :precision="1"></el-input-number>
				</el-form-item>
			</el-form>
            <div slot="footer">
				<el-button type="primary" @click="close" class="bgc-bv">取 消</el-button>
				<el-button
				type="primary"
				class="bgc-bv"
				@click="doSave"
				>确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>

export default {
	name: 'file-editor-pop',
    props: {
		show: Boolean,
		fileInfo: Object
    },
	data() {
		return {
			origin: {},
			rules: {
				dispName: [
					{ required: true, message: '请输入名称', trigger: 'blur' }
				],
				kpointLessonNum: [
					{ required: true, message: '请输入学时', trigger: 'blur' }
				]
			},
		}
    },
    watch: {
        show(val) {
            if(val) {
				this.origin = Object.assign({}, this.fileInfo);
            }
        }
    },
    created() {
    },
	methods: {
        close() {
			this.$refs.form.clearValidate();
			this.$emit('update:show', false);
        },
        doSave() {
			let re = this.$refs.form.validate(re => {
				if (re) {
					this.$emit('change', this.origin);
					this.close();
				}
			});
        }
	}
}
</script>

<style lang="less" scoped>
</style>
