<template>
  <el-dialog
    class="MenuStyle"
    title="目录样式"
    :visible.sync="dialogVisible"
    top="5%"
    width="800px"
    :center="false"
    :before-close="doClose"
  >
    <div class="box">
      <div class="top">
        <img src="@/assets/style.png" alt="" />
        <span class="btn" @click="choose(1)">使用</span>
      </div>
      <div class="top bottom">
        <img src="@/assets/style1.png" alt="" />
        <span class="btn" @click="choose(2)">使用</span>
      </div>
    </div>
  </el-dialog>
</template>
<script>
// import CourseForm from "@/views/resourse/popup/CourseForm";
// import CourseTable from "@/views/resourse/popup/CourseTable";
export default {
  name: "course",
  components: {
    // CourseForm,
    // CourseTable
  },
  data() {
    return {
      dialogVisible: false,
      activeName: "first"
    };
  },
  methods: {
    showPopUp() {
      this.dialogVisible = true;
    },
    doClose() {
      this.dialogVisible = false;
    },
    choose(stu) {
      this.parentPsBack(stu);
    },
    // tab回传
    tableBack(id) {
      this.parentPsBack(id);
    },
    formBack(id) {
      this.parentPsBack(id);
    },
    // 给父组件传参
    parentPsBack(ret) {
      this.$emit("eventBus", ret);
      this.doClose();
    }
  }
};
</script>
<style lang="less">
.MenuStyle .box {
  padding: 1rem 1rem 0;
  position: relative;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  }
  img {
    width: 25.1rem;
    height: 10.6rem;
  }
  .btn {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    color: #333333;
    text-align: center;
    border-radius: 50%;
    background-color: #E7E7E7;
    border:1px solid rgba(191, 191, 191, 1);
    cursor: pointer;
  }
  .bottom {
    border-top: 0.1rem dashed #aaa;
    padding-top: 1rem;
  }
}
.box::after {
  position: absolute;
  top: 50%;
  width: 80%;
  content: "";
  padding: 2rem 0;
}
</style>
