<template>
  <section class="CourseTable" style="width: 100%;">
    <div class="operationControl"  style="border:0">
      <div class="searchbox">
        <div title="试卷名称" class="searchboxItem ci-full">
          <span class="itemLabel">试卷名称:</span>
          <el-input clearable v-model="kpointName" type="text" size="small" placeholder="请输入试卷名称" />
        </div>
        <span title="培训类型" class="searchboxItem ci-full flexcc">
          <span class="itemLabel" style="width:110px">培训类型:</span>
          <tree
            ref="tree"
            @eventBtn="childBack"
            :ruleForm="ruleForm"
            size="small"
            @clearParams="clearParams"
            :modal="false"
            typeStu
          />
        </span>
        <div title="试卷来源" class="searchboxItem ci-full">
          <span class="itemLabel" style="width:100px">试卷来源:</span>
          <el-select clearable size="small" v-model="courseOrigin" placeholder="请选择">
            <el-option
              v-for="item in courseOriginList"
              :key="'courseOrigin' + item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div>
          <el-button class="bgc-bv" @click="getData()">查询</el-button>
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          border
        >
          <el-table-column label width="50px" align="center">
            <template slot-scope="scope">
              <el-radio v-model="radio" :label="scope.row" />
            </template>
          </el-table-column>
          <el-table-column label="试卷名称" align="center" show-overflow-tooltip prop="paperName" />
          <el-table-column
            label="培训类型"
            align="center"
            show-overflow-tooltip
            prop="trainTypeNamePath"
          />
          <el-table-column
            label="行业类型"
            align="center"
            show-overflow-tooltip
            prop="industryNamePath"
          />
          <el-table-column label="岗位类型" align="center" prop="postName" show-overflow-tooltip/>
          <el-table-column label="职业/工种" align="center" prop="occupationNamePath" show-overflow-tooltip/>
          <el-table-column label="培训等级" align="center" prop="trainLevelName" show-overflow-tooltip/>
          <el-table-column label="试卷来源" align="center">
            <template slot-scope="scope">
              <span>
                {{
                $setDictionary("PAPERORGIN", scope.row.paperOrgin)
                }}
              </span>
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" style="padding:15px 0"/>
    <span class="dialog-footer">
      <div>
        <el-button class="bgc-bv" @click="dialogClose">取消</el-button>
        <el-button class="bgc-bv" @click="doOk">确定</el-button>
      </div>
    </span>
  </section>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "course",
  components: {
    Empty,
    PageNum,
    tree
  },
  mixins: [List],
  data() {
    return {
      kpointName: "",
      traintype: "",
      courseOrigin: "",
      courseOriginList: [],
      radio: "",
      params: {},
      ruleForm: {
        Trainingtype: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      traintypeList: "getTraintype"
    })
  },
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    tinit() {
      this.getCourseOriginList();
      this.init();
    },
    getCourseOriginList() {
      const courseOriginList = this.$setDictionary("PAPERORGIN", "list");
      const list = [];
      for (const key in courseOriginList) {
        list.push({
          value: key,
          label: courseOriginList[key]
        });
      }
      this.courseOriginList = list;
    },
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.kpointName) {
        params.paperName = this.kpointName;
      }
      if (this.courseOrigin) {
        params.paperOrgin = this.courseOrigin;
      }
      //
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.radio = "";
      this.doFetch({
        url: "/biz/paper/queryList",
        params,
        pageNum
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      const { radio } = this;
      if (radio) {
        this.$emit("eventBus", radio);
      } else {
        this.$message({
          message: "请选择要关联的课件",
          type: "warning"
        });
      }
    },
    dialogClose() {
      this.$emit("eventBus");
      // this.params = {};
    }
  }
};
</script>
<style lang="less">
.CourseTable {
  .el-radio {
    .el-radio__label {
      display: none;
    }
  }
}
</style>
